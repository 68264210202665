import React, { ReactElement } from "react"

import { graphql, useStaticQuery, Link } from "gatsby"

import SEO from "../components/SEO"

// Component imports
import Layout from "../components/Layout"
import BackgroundImage from "gatsby-background-image"
import Section from "../components/Section"

function NotFound(): ReactElement {
  const { background } = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "background_header_blurred.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404" />
      <BackgroundImage
        fluid={background.childImageSharp.fluid}
        className="h-full"
      >
        <Section className="flex flex-col items-center justify-center h-full">
          <h2 className="text-3xl font-bold text-center uppercase lg:mb-4 lg:text-5xl">
            404: Page not found!
          </h2>
          <Link
            to="/"
            className="block w-64 py-4 mx-auto mt-5 text-xs text-center transition-all duration-300 border-2 border-gray-500 ease-easeInOutQuint hover:bg-white hover:text-black hover:border-transparent hover:font-semibold focus:bg-white focus:font-semibold focus:border-transparent focus:outline-none active:outline-none"
          >
            Back to home
          </Link>
        </Section>
      </BackgroundImage>
    </Layout>
  )
}

export default NotFound
